const paywall = {
    applications: 'تم الوصول إلى الحد الأقصى لعدد التطبيقات ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لتلبية احتياجات فريقك. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    applications_other: 'تم الوصول إلى الحد الأقصى لعدد التطبيقات ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لتلبية احتياجات فريقك. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    machine_to_machine_feature: 'قم بالترقية إلى الخطة <strong>Pro</strong> للحصول على تطبيقات إضافية للتواصل بين الأجهزة والاستمتاع بجميع الميزات المتميزة. <a>اتصل بنا</a> إذا كان لديك أي أسئلة.',
    machine_to_machine: 'تم الوصول إلى الحد الأقصى لعدد تطبيقات التواصل بين الأجهزة ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لتلبية احتياجات فريقك. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    machine_to_machine_other: 'تم الوصول إلى الحد الأقصى لعدد تطبيقات التواصل بين الأجهزة ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لتلبية احتياجات فريقك. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    resources: 'تم الوصول إلى الحد الأقصى لعدد مصادر واجهة برمجة التطبيقات ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لتلبية احتياجات فريقك. <a>اتصل بنا</a> لأي مساعدة.',
    resources_other: 'تم الوصول إلى الحد الأقصى لعدد مصادر واجهة برمجة التطبيقات ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لتلبية احتياجات فريقك. <a>اتصل بنا</a> لأي مساعدة.',
    scopes_per_resource: 'تم الوصول إلى الحد الأقصى لعدد الأذونات ({{count, number}}) لكل مصدر واجهة برمجة التطبيقات في الخطة <planName/>. قم بترقية الخطة للتوسع. <a>اتصل بنا</a> لأي مساعدة.',
    scopes_per_resource_other: 'تم الوصول إلى الحد الأقصى لعدد الأذونات ({{count, number}}) لكل مصدر واجهة برمجة التطبيقات في الخطة <planName/>. قم بترقية الخطة للتوسع. <a>اتصل بنا</a> لأي مساعدة.',
    custom_domain: 'قم بفتح وظيفة النطاق المخصص عن طريق الترقية إلى الخطة <strong>Hobby</strong> أو <strong>Pro</strong>. لا تتردد في <a>الاتصال بنا</a> إذا كنت بحاجة إلى أي مساعدة.',
    social_connectors: 'تم الوصول إلى الحد الأقصى لعدد موصلات التواصل الاجتماعي ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لتلبية احتياجات فريقك وللقدرة على إنشاء موصلات خاصة باستخدام بروتوكولات OIDC و OAuth 2.0 و SAML. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    social_connectors_other: 'تم الوصول إلى الحد الأقصى لعدد موصلات التواصل الاجتماعي ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لتلبية احتياجات فريقك وللقدرة على إنشاء موصلات خاصة باستخدام بروتوكولات OIDC و OAuth 2.0 و SAML. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    standard_connectors_feature: 'قم بالترقية إلى الخطة <strong>Hobby</strong> أو <strong>Pro</strong> لإنشاء موصلات خاصة باستخدام بروتوكولات OIDC و OAuth 2.0 و SAML، بالإضافة إلى موصلات اجتماعية غير محدودة وجميع الميزات المتميزة. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    standard_connectors: 'تم الوصول إلى الحد الأقصى لعدد موصلات التواصل الاجتماعي ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لتلبية احتياجات فريقك وللقدرة على إنشاء موصلات خاصة باستخدام بروتوكولات OIDC و OAuth 2.0 و SAML. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    standard_connectors_other: 'تم الوصول إلى الحد الأقصى لعدد موصلات التواصل الاجتماعي ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لتلبية احتياجات فريقك وللقدرة على إنشاء موصلات خاصة باستخدام بروتوكولات OIDC و OAuth 2.0 و SAML. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    standard_connectors_pro: 'تم الوصول إلى الحد الأقصى لعدد موصلات قياسية ({{count, number}}) في الخطة <planName/>. قم بالترقية إلى الخطة المؤسسية للحصول على موصلات اجتماعية إضافية والقدرة على إنشاء موصلات خاصة باستخدام بروتوكولات OIDC و OAuth 2.0 و SAML. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    standard_connectors_pro_other: 'تم الوصول إلى الحد الأقصى لعدد موصلات قياسية ({{count, number}}) في الخطة <planName/>. قم بالترقية إلى الخطة المؤسسية للحصول على موصلات اجتماعية إضافية والقدرة على إنشاء موصلات خاصة باستخدام بروتوكولات OIDC و OAuth 2.0 و SAML. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    roles: 'تم الوصول إلى الحد الأقصى لعدد الأدوار ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لإضافة أدوار وأذونات إضافية. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    roles_other: 'تم الوصول إلى الحد الأقصى لعدد الأدوار ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لإضافة أدوار وأذونات إضافية. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    machine_to_machine_roles: 'تم الوصول إلى الحد الأقصى لعدد أدوار التواصل بين الأجهزة ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لإضافة أدوار وأذونات إضافية. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    machine_to_machine_roles_other: 'تم الوصول إلى الحد الأقصى لعدد أدوار التواصل بين الأجهزة ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لإضافة أدوار وأذونات إضافية. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    scopes_per_role: 'تم الوصول إلى الحد الأقصى لعدد الأذونات ({{count, number}}) لكل دور في الخطة <planName/>. قم بترقية الخطة لإضافة أدوار وأذونات إضافية. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    scopes_per_role_other: 'تم الوصول إلى الحد الأقصى لعدد الأذونات ({{count, number}}) لكل دور في الخطة <planName/>. قم بترقية الخطة لإضافة أدوار وأذونات إضافية. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    saml_applications_oss: 'التطبيق الإضافي لـ SAML متاح مع خطة Logto المؤسسية. اتصل بنا إذا كنت بحاجة إلى مساعدة.',
    logto_pricing_button_text: 'تسعير Logto Cloud',
    hooks: 'تم الوصول إلى الحد الأقصى لعدد الويب هوك ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لإنشاء المزيد من الويب هوك. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    hooks_other: 'تم الوصول إلى الحد الأقصى لعدد الويب هوك ({{count, number}}) في الخطة <planName/>. قم بترقية الخطة لإنشاء المزيد من الويب هوك. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    mfa: 'قم بفتح وظيفة التحقق الثنائي للتحقق من الأمان عن طريق الترقية إلى خطة مدفوعة. لا تتردد في <a>الاتصال بنا</a> إذا كنت بحاجة إلى أي مساعدة.',
    organizations: 'قم بفتح وظيفة المؤسسات عن طريق الترقية إلى خطة مدفوعة. لا تتردد في <a>الاتصال بنا</a> إذا كنت بحاجة إلى أي مساعدة.',
    third_party_apps: 'قم بفتح Logto كموفر هوية لتطبيقات الطرف الثالث عن طريق الترقية إلى خطة مدفوعة. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    sso_connectors: 'قم بفتح ميزة تسجيل الدخول الموحد للمؤسسات عن طريق الترقية إلى خطة مدفوعة. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    tenant_members: 'قم بفتح ميزة التعاون عن طريق الترقية إلى خطة مدفوعة. لأي مساعدة، لا تتردد في <a>الاتصال بنا</a>.',
    tenant_members_dev_plan: 'لقد وصلت إلى الحد الأقصى لعدد الأعضاء ({{limit}}) في الخطة الحالية. قم بإزالة عضو أو إلغاء دعوة معلقة لإضافة شخص جديد. هل تحتاج إلى مزيد من المقاعد؟ لا تتردد في الاتصال بنا.',
    custom_jwt: {
        title: 'إضافة مطالبات مخصصة',
        description: 'قم بالترقية إلى خطة مدفوعة للحصول على وظيفة JWT المخصصة والفوائد المتميزة. لا تتردد في <a>الاتصال بنا</a> إذا كان لديك أي أسئلة.',
    },
    bring_your_ui: 'قم بالترقية إلى خطة مدفوعة للحصول على وظيفة إحضار واجهة المستخدم المخصصة والفوائد المتميزة.',
};
export default Object.freeze(paywall);
